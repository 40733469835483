/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {BaseController} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/controller-factory/BaseController';
import {ControllerParams} from '@wix/yoshi-flow-editor';
import {GalleryStore} from './GalleryStore';
import {IGalleryControllerConfig, VeloInputs, VeloInputsOnItemSelected} from '../types/galleryTypes';
import {appClient, Scope} from '@wix/app-settings-client';
import {APP_SETTINGS_CDN} from '@wix/wixstores-client-core/dist/es/src/constants';
import {createSlotVeloAPIFactory} from '@wix/widget-plugins-ooi/velo';
import _ from 'lodash';
import {QueryParamsService} from '@wix/wixstores-client-storefront-sdk';
import {Experiments, WixQueryParams} from '../constants';
import {GalleryControllerExtraParams} from '../components/PlatformizedGallery/controller';
import {ExternalDataSourceCallbacks} from '../types/externalDataSourceCallbacks';
import {getQueryParamsDiff} from '../services/queryParams/queryParamsUtils';

type ResolveFn<T> = (value: T | PromiseLike<T>) => void;

export class GalleryController extends BaseController {
  private readonly compId: string;
  private galleryStore: GalleryStore;
  private veloInputs: VeloInputs;
  private readonly waitUntilReady: Promise<void>;
  private onReadyResolver: ResolveFn<void>;
  private initializeCallback: Function;
  private readonly queryParamsService: QueryParamsService;
  private rerenderUrlHeaders: {lang: string; currency: string};
  private readonly slotAPIFactory: ReturnType<typeof createSlotVeloAPIFactory>;
  private previousQueryParams: {[key: string]: string};
  private config: IGalleryControllerConfig;
  private readonly componentId: string;
  private readonly isUsingExternalDataSource: boolean;
  private externalDataSourceCallbacks: ExternalDataSourceCallbacks;

  private async initializeVeloDataAfterStoreCreated() {
    await this.waitUntilReady;
    this.galleryStore.setVeloInputs(this.veloInputs);
    return this.galleryStore.setInitialState();
  }

  private async initGalleryStore() {
    if (this.isUsingExternalDataSource) {
      /*istanbul ignore next: cannot test*/
      if (!this.externalDataSourceCallbacks) {
        throw new Error(
          'External data source callbacks are not set, event though gallery is using external data source.'
        );
      }
      this.galleryStore.setExternalDataSourceCallbacks(this.externalDataSourceCallbacks);
    }

    await this.galleryStore
      .setInitialState()
      .then(() => this.onReadyResolver())
      .catch(this.reportError);
  }

  public setVeloInputs = (veloInputs: VeloInputs) => {
    this.veloInputs = {
      ...this.veloInputs,
      ...veloInputs,
    };
  };

  public exports = () => {
    //eslint-disable-next-line @typescript-eslint/no-this-alias
    const controller = this;
    return {
      refreshGallery: async () => {
        /*istanbul ignore next: cannot test*/
        if (this.galleryStore) {
          await controller.initGalleryStore();
        }
      },
      setExternalDataSourceCallbacks: async (callbacks: ExternalDataSourceCallbacks) => {
        this.externalDataSourceCallbacks = callbacks;
        /*istanbul ignore next: cannot test*/
        if (this.galleryStore) {
          await controller.initGalleryStore();
        }
      },
      setCollection: async (collectionId: string): Promise<void> => {
        this.setVeloInputs({collectionId, productIds: undefined});
        if (this.galleryStore) {
          return this.initializeVeloDataAfterStoreCreated();
        }
      },
      setProducts: async (productIds: string[]) => {
        this.setVeloInputs({productIds, collectionId: undefined});
        if (this.galleryStore) {
          return this.initializeVeloDataAfterStoreCreated();
        }
      },
      onInitialize: (cb: Function) => {
        this.initializeCallback = cb;
      },
      onItemSelected: (
        callBack: VeloInputsOnItemSelected['callBack'],
        options: VeloInputsOnItemSelected['options'] = {preventNavigation: false}
      ) => {
        //TODO:Zeev: this is part of Categories POC
        this.setVeloInputs({onItemSelected: {callBack, options}});
        if (this.galleryStore) {
          return this.initializeVeloDataAfterStoreCreated();
        }
      },
    };
  };

  public onConfigUpdate = async (config: IGalleryControllerConfig) => {
    this.config = _.clone(config);
    await this.galleryStore.updateState(this.config, {APP: {}, COMPONENT: {}});
  };

  /* istanbul ignore next: for PR only, will be tested before merge */
  public onAppSettingsUpdate = (updates: {[key: string]: any}) => {
    if (
      this.siteStore.experiments.enabled(Experiments.EditorGalleryOOI) &&
      updates.scope === Scope.COMPONENT &&
      updates.source === 'app-settings'
    ) {
      void this.galleryStore.updateState(this.config, {
        APP: undefined,
        COMPONENT: {},
        appSettings: updates.payload,
      });
    }
  };

  public getFreeTexts = (): string[] => {
    return [];
  };

  /* istanbul ignore next: nothing to test  */
  protected readonly reportError = (e: string | Error) => {
    console.error(e);
    this.flowAPI.reportError(e);
  };

  constructor(controllerParams: ControllerParams, extraParams?: GalleryControllerExtraParams) {
    super(controllerParams);
    this.isUsingExternalDataSource = extraParams?.isUsingExternalDataSource ?? false;
    this.config = _.clone(controllerParams.controllerConfig.config);
    this.compId = controllerParams.controllerConfig.compId;
    this.componentId = controllerParams.controllerConfig.type;
    this.queryParamsService = new QueryParamsService<typeof this.siteStore>(this.siteStore);
    this.rerenderUrlHeaders = {
      [WixQueryParams.Lang]: this.queryParamsService.getQueryParam(WixQueryParams.Lang),
      [WixQueryParams.Currency]: this.queryParamsService.getQueryParam(WixQueryParams.Currency),
    };
    const isEditor = typeof window !== 'undefined' && window.Wix;
    this.slotAPIFactory = createSlotVeloAPIFactory(controllerParams.controllerConfig);

    /* istanbul ignore else: todo(flow-editor): test */
    if (isEditor) {
      this.listenToAppSettingsUpdate();
    }

    this.waitUntilReady = new Promise((resolve) => {
      this.onReadyResolver = resolve;
    });
  }

  private listenToAppSettingsUpdate() {
    const appSettingsClient = appClient({scope: Scope.COMPONENT, cdnUrl: APP_SETTINGS_CDN});
    appSettingsClient.onChange((pb) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.galleryStore.updateState(this.config, {APP: undefined, COMPONENT: {}, appSettings: pb});
    });
  }

  /* eslint-disable sonarjs/cognitive-complexity */
  public readonly load = async () => {
    this.siteStore.experiments.enabled(Experiments.PreventGalleryFullRefreshOnUrlChange)
      ? await this.reactToUrlChange()
      : await this.oldLoad();
  };

  private async oldLoad() {
    if (this.initializeCallback) {
      await Promise.resolve(this.initializeCallback()).catch(this.flowAPI.reportError);
    }

    const newQueryParams = this.siteStore.location.query;
    if (!this.previousQueryParams) {
      this.previousQueryParams = {...newQueryParams};
    }

    if (this.galleryStore) {
      const {page: lastPage, ...lastQueryParams} = this.previousQueryParams;
      const {page: currentPage, ...currentQueryParams} = newQueryParams;
      this.previousQueryParams = {...newQueryParams};
      if (_.isEqual(lastQueryParams, currentQueryParams)) {
        if (lastPage !== currentPage) {
          this.galleryStore.updateAppOnQueryParamsChange({isPageUpdated: true});
        }
        return;
      }
    }

    /* istanbul ignore next: cannot test */
    if (this.galleryStore && this.siteStore.experiments.enabled(Experiments.FixGalleryRenderingWhenUrlChanges)) {
      const currentLang = this.queryParamsService.getQueryParam(WixQueryParams.Lang);
      const currentCurrency = this.queryParamsService.getQueryParam(WixQueryParams.Currency);
      const {currency, lang} = this.rerenderUrlHeaders;
      if (currency === currentCurrency && lang === currentLang) {
        return;
      } else {
        this.rerenderUrlHeaders = {
          [WixQueryParams.Lang]: currentLang,
          [WixQueryParams.Currency]: currentCurrency,
        };
      }
    }

    const isInitialLoad = !this.galleryStore;

    this.galleryStore = new GalleryStore(
      this.config,
      this.setProps.bind(this),
      this.siteStore,
      this.compId,
      this.componentId,
      this.flowAPI,
      this.reportError,
      this.slotAPIFactory,
      this.veloInputs
    );

    /* istanbul ignore if: irrelevant */
    if (this.isUsingExternalDataSource && /* istanbul ignore next: irrelevant */ isInitialLoad) {
      // initialization happening upon setting external data source
      return;
    }

    await this.initGalleryStore();
  }

  private reactToUrlChange() {
    if (_.isEqual(this.previousQueryParams, this.siteStore.location.query)) {
      return;
    }

    const newQueryParams = this.siteStore.location.query;
    const {isPageUpdated, isFiltersUpdated, isSortUpdated, numberOfKnownChangedKeysTypes, isLanguageOrCurrencyUpdated} =
      getQueryParamsDiff({
        previousQueryParams: this.previousQueryParams,
        newQueryParams,
        filterQueryParamsKeys: this.galleryStore.getQueryParamsFiltersKeys,
      });
    this.previousQueryParams = {...newQueryParams};

    if (isLanguageOrCurrencyUpdated) {
      return this.galleryStore.setInitialState();
    }

    if ((isPageUpdated || isFiltersUpdated || isSortUpdated) && numberOfKnownChangedKeysTypes === 1) {
      this.galleryStore.updateAppOnQueryParamsChange({isPageUpdated, isFiltersUpdated, isSortUpdated});
    }
  }

  public readonly init = async () => {
    if (!this.siteStore.experiments.enabled(Experiments.PreventGalleryFullRefreshOnUrlChange)) {
      await this.oldLoad();
      return;
    }
    if (this.initializeCallback) {
      await Promise.resolve(this.initializeCallback()).catch(this.flowAPI.reportError);
    }
    this.previousQueryParams = {...this.siteStore.location.query};
    await this.initializeGalleryStore(true);
  };

  private async initializeGalleryStore(isInitialLoad: boolean) {
    this.galleryStore = new GalleryStore(
      this.config,
      this.setProps.bind(this),
      this.siteStore,
      this.compId,
      this.componentId,
      this.flowAPI,
      this.reportError,
      this.slotAPIFactory,
      this.veloInputs
    );

    if (this.isUsingExternalDataSource && isInitialLoad) {
      // initialization happening upon setting external data source
      return;
    }

    await this.initGalleryStore();
  }

  public getOptionsOverrides() {
    /* istanbul ignore next: does not affect unit tests */
    return this.siteStore.experiments.enabled(Experiments.GalleryFetchAppSettingsOnce)
      ? {}
      : {shouldSkipMultilingual: false};
  }
}
